<template>
  <div class="hospital-manage-content">
    <el-scrollbar style="height: 100%">
      <part-title title="医院配置"></part-title>
      <el-button  class="content-btn" size="small" type="primary" @click="handleAddHospital">
        <i class="el-icon-plus"></i>
        添加医院
      </el-button>
      <div id="login_container" style="transform: scale(0.7)"></div>
      <el-table
      :data="tableData"
      style="width: 100%">
      <el-table-column
        type="index"
        label="序号">
      </el-table-column>
      <el-table-column
        prop="name"
        label="医院名称"
        min-width="150px">
        <template slot-scope="scope">
          <span v-if="scope.row.name">
            {{ scope.row.name }}
          </span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="inviteCode"
        label="医院邀请码">
        <template slot-scope="scope">
          <span v-if="scope.row.inviteCode">
            {{ scope.row.inviteCode }}
          </span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="creatorName"
        label="创建人">
        <template slot-scope="scope">
          <span v-if="scope.row.creatorName">
            {{ scope.row.creatorName }}
          </span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="createAt"
        label="添加时间"
        min-width="120">
        <template slot-scope="scope">
            <span>{{moment.unix(scope.row.createAt / 1000).format('Y-MM-DD HH:mm:ss')}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="isEnable"
        label="医院状态"
        width="100px">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="light" :content="scope.row.isEnable === 0 ? '冻结': '启用'" placement="top">
            <el-switch
              v-model="scope.row.isEnable"
              :active-value="1"
              :inactive-value="0"
              @change="onChangeStatus(scope.row)">
            </el-switch>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        width="200">
        <template slot-scope="scope">
          <el-button @click="onEdit(scope.row)" type="text" size="small">编辑</el-button>
          <el-button type="text" size="small" @click="onDownloadQrCode(scope.row)">下载二维码</el-button>
          <el-button type="text" size="small" @click="onAddDoctor(scope.row)">添加医生</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="height: 80px;"></div>
    <!-- 分页 -->
    <Page
        @handleSizeChange="sizeChange"
        @handleCurrentChange="pageChange"
        :current-page="currentPage"
        :page-size="pageSize"
        :total="totalPage"
    ></Page>
    </el-scrollbar>
    <AddHospital
    :visible.sync="isVisibleAddHospitalDrawer"
    @addHospital="onAddHospital"
    />
    <EditHospital
    :visible.sync="isVisibleEditHospitalDrawer"
    :hosPitalInfo="hosPitalInfo"
    @editHospital="onEditHospital"
    />
  </div>
</template>
<script>
import PartTitle from '@/components/Part'
import Page from '@/components/Page'
import { getHospitalList, getHospitalInfo, editHospitalStatus, getInviteQrcode } from '@/serve/module/hospitalManage'
import { dataToFile } from '@/utils/file'
import AddHospital from './addHospital.vue'
import EditHospital from './editHospital.vue'
const moment = require('moment')
export default {
  components: {
    PartTitle,
    Page,
    AddHospital,
    EditHospital
  },
  data () {
    return {
      moment: moment,
      tableData: [],
      handleOpts: {
      },
      totalPage: 0, // 总条数
      currentPage: 1, // 当前条数
      pageSize: 20, // 每页条数

      isVisibleAddHospitalDrawer: false,
      isVisibleEditHospitalDrawer: false,

      hospitalId: '',

      ruleForm: {},
      rules: {
        name: [
          { required: true, message: '请输入医院名称', trigger: 'blur' }
        ],
        inviteCode: [
          { required: true, message: '请输入医院邀请码', trigger: 'blur' },
          { min: 4, max: 4, message: '格式错误，长度在 4 个字符', trigger: 'change' },
          { pattern: /^\d+$/, message: '格式错误，请输入数字' }
        ]
      },
      isEditDrawer: false,

      changeHospitalStatusHandle: false,

      http_url: '',

      baseurl: {
        ddurl: 'http://test1-admin.ciedtracker.com:8080/login',
        appid: '2585353314'
      },

      hosPitalInfo: {}
    }
  },
  created () {
    this.getHospitalList(1, 20)
  },
  methods: {
    onAddHospital () {
      this.getHospitalList(1, 20)
    },
    onEditHospital () {
      this.getHospitalList(1, 20)
    },
    ddLoginInit () {
      const url = encodeURIComponent(this.baseurl.ddurl)// 此处url写钉钉回调地址
      const appid = this.baseurl.appid// 填写自己在钉钉开发者平台配的appid
      const goto = encodeURIComponent(
        `https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${appid}&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=${url}`
      )
    },
    getHospitalList (currentPage, pageSize) {
      getHospitalList({
        pageNo: currentPage,
        pageSize: pageSize
      }).then(res => {
        if (res && res.code === 200) {
          this.tableData = res.data.data
          this.totalPage = res.data.total
        } else {
          this.$message.error(res.message)
        }
      })
    },
    sizeChange (val) {
      this.pageSize = val
      this.getHospitalList(this.currentPage, val)
    },
    pageChange (val) {
      this.currentPage = val
      this.getHospitalList(val, this.pageSize)
    },
    onEdit (row) {
      this.isEditDrawer = true
      this.isVisibleEditHospitalDrawer = true
      getHospitalInfo(row.id).then(res => {
        if (res) {
          this.hosPitalInfo = res.data
        }
      })
    },
    onDownloadQrCode (row) {
      getInviteQrcode(row.id).then(res => {
        if (res.code && res.code !== 200) {
          this.$message.error(res.message)
        } else {
          dataToFile(res)
        }
      }).catch(() => {
        //
      })
    },
    onAddDoctor (row) {
    // 跳转医生管理页面
      console.log('666:', row)
      this.$router.push({
        name: 'addDoctor',
        query: {
          id: row.id,
          name: row.name
        }
      })
    },
    handleAddHospital () {
      this.isVisibleAddHospitalDrawer = true
      this.isEditDrawer = false
      this.ruleForm = {}
    },
    editHospitalStatus (id, status) {
      editHospitalStatus({
        id: id,
        status: status
      }).then(res => {
        if (res.code === 200) {
          this.getHospitalList(1, 20)
          if (status === 'enable') {
            this.$message.success('医院启用成功')
          } else {
            this.$message.success('医院停用成功')
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    onChangeStatus (row) {
      if (row.isEnable === 1) {
      // 启用
        row.isEnable = 0
        this.editHospitalStatus(row.id, 'enable')
      } else {
      // 停用
        row.isEnable = 1
        this.$confirm('确定停用该医院账号？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.editHospitalStatus(row.id, 'disable')
        }).catch(() => {
        //
        })
      }
    }
  }
}
</script>
<style scoped lang="scss">
.hospital-manage-content {
  margin: 0 24px;
  .content-btn {
    float: right;
    margin-bottom: 12px;
  }
}
::v-deep .el-drawer__header {
  font-weight: 500;
  font-size: 16px;
  color: #0F1114;
  padding: 16px 0px 16px 24px;
  margin-bottom: 0;
  box-shadow: inset 0px -1px 0px #E7E8EA;
}

.line {
  height: 1px;
  width: 400px;
  position: fixed;
  bottom: 64px;
  right: 0;
  box-shadow: inset 0px 1px 0px #E7E8EA;
}
.drawer-footer {
  position: fixed;
  bottom: 0;
  height: 48px;
  right: 20px;
}
::v-deep .el-button--text {
  font-size: 14px;
  font-weight: 400;
}
.page,.page-shadow {
  background: #fff;
  position: fixed;
  bottom: 0px;
  right: 0px;
  left: 210px;
  z-index: 100;
  padding: 20px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
::v-deep .el-form-item {
 margin-bottom: 16px;
}
</style>
